* {
  transition: 0.3s;
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
}

html, body {
  background: rgb(222,233,244);
  background: radial-gradient(circle, rgb(196, 220, 245) 0%, rgba(255,255,255,1) 100%);
}

h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 2px;
}

h2 {
  font-size: 36px;
  letter-spacing: 1.6px;
}

p {
  font-size: 18px;
  letter-spacing: 1.3px;
}

button, a, label {
  cursor: pointer;
}

button:hover, a:hover, label:hover {
  opacity: 0.7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}


.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 100px;
}