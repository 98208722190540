.App {
  text-align: center;
}

.navbar {
  display: flex;
  list-style-type: none;
  justify-content: flex-start;
  float: left;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  width: 100%;
  padding: 10px;
}

.link {
  text-decoration: none;
  color: white;
  float: left;
  margin: 0 2vh;
  font-size: 1.5em;
  opacity: 1;
  display: flex;
  align-items: center;
}

.link:hover {
  color: white;
}

a.activeLink {
  color: white;
}

a.activeLink:hover {
  color: white;
}

#logo {
  flex: 1;
}